<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:body>
                <b-row>
                    <b-col>
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('seedsConfig.programType')"
                        label-for="email"
                        >
                        <v-select name="program_type_id"
                        v-model="search.program_type_id"
                        label="text"
                        :options= programTypeList
                        :placeholder="$t('globalTrans.select')"
                        />
                        </b-form-group>
                    </b-col>
                     <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                        <ValidationProvider name="Organization" vid="org_id">
                            <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="org_id"
                            >
                            <template v-slot:label>
                            {{$t('org_pro.organization')}}
                            </template>
                            <b-form-select
                            plain
                            v-model="search.org_id"
                            :options="orgList"
                            id="org_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col >
                    <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('seedsConfig.programType')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <!-- <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
                             {{  $t('globalTrans.add_new') }}
                        </b-button> -->
                        <b-button class="btn_add_new" size="sm" v-b-modal.modal-4 @click="resetId">
                            <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" bordered striped hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(type_name)="data">
                                        <span class="capitalize">{{ data.item.type_name }}</span>
                                        </template>
                                        <template v-slot:cell(stock_duration)="data">
                                        {{ $n(data.item.stock_duration, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <!-- <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                            <span v-if="data.item.status === 1">
                                                <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="fas fa-window-close m-0"></i></b-button>
                                            </span>
                                            <span v-if="data.item.status === 2">
                                                <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="fa fa-check m-0"></i></b-button>
                                            </span> -->
                                            <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                                                <i class="ri-ball-pen-fill"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 1">
                                                <i class="fas fa-toggle-on"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 2">
                                                <i class="fa fa-toggle-off"></i>
                                            </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>

    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { programTypeList, programTypeToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form
    },
    data () {
        return {
            search: {
                program_type_id: '',
                org_id: this.$store.state.dataFilters.orgId
            }
        }
    },
    computed: {
        programTypeList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.programTypeList.filter(item => item.status === 1)
        },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('seedsConfig.programType') + ' ' + this.$t('globalTrans.entry') : this.$t('seedsConfig.programType') + ' ' + this.$t('globalTrans.update')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('seedsConfig.programType'), class: 'text-center' },
                { label: this.$t('seedsSeeds.organization'), class: 'text-center' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'type_name_bn' },
                { key: 'org_name_bn' },
                { key: 'status' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'type_name' },
                { key: 'org_name' },
                { key: 'status' },
                { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        },
        orgList: function () {
            return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
        }
    },
    async created () {
        this.loadData()
    },
    mounted () {
        core.index()
    },
    methods: {
        dataChange () {
            this.loadData()
        },
        default () {
            return {
                id: this.rows.length,
                type_name: '',
                type_name_bn: '',
                editable: false
            }
        },
        async searchData () {
            this.loadData()
        },
        remove (item) {
        this.changeStatus(seedFertilizerServiceBaseUrl, programTypeToggleStatus, item, 'seeds_fertilizer', 'programTypeList')
        },
        loadData () {
            var programTypeIdSerach = ''
            if (this.search.program_type_id) {
                programTypeIdSerach = this.search.program_type_id.value
            }
            const params = Object.assign({}, this.search, { master_program_type_id: programTypeIdSerach, page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(seedFertilizerServiceBaseUrl, programTypeList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', this.relationalData(response.data.data))
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        relationalData (data) {
            const recommendationData = data.map(item => {
                const orgData = this.$store.state.commonObj.organizationProfileList.find(listitem => listitem.value === item.org_id && listitem.status === 0)
                const tmpBindingData = {
                org_name: orgData !== undefined ? orgData.text_en : '',
                org_name_bn: orgData !== undefined ? orgData.text_bn : ''
                }
            return Object.assign({}, item, tmpBindingData)
            })
            return recommendationData
        }
    }
}
</script>
